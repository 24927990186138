.App {
  text-align: center;
  background-color: #fcde67;  
  color: #030e12;
  font-family: "Gill Sans", sans-serif;
  word-spacing: 0.2em;
  font-stretch: expanded;
}

.container {
  /* text-shadow: -1px 0 white, 0 2px white, 2px 0 white, 0 -1px white; */
  font-size: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10%;
  height: 80vh;
  min-height: 80vh;;
}

.image-app {
  border: 2px solid #000;
  box-shadow: 10px 10px 5px #000;
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #000;
}

.cmd-image {
  margin-bottom: -10px;
  margin-right: 10px;
}

/* down arrow css */

.down-arrow {
  position:absolute;
  left: calc(50% - 14px);
  width: 0;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
  animation: jumpInfinite 1.5s infinite;
  top: calc(90vh);

}

.down-arrow:after {
  content: " ";
  position: absolute;
  top: 12px;
  left: -10px;
  width: 16px;
  height: 16px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  border-radius: 4px;
  transform: rotateZ(45deg);
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}

/* end down arrow css */


/* From uiverse.io by @barisdogansutcu */
.download-button {
  position: relative;
  border-width: 0;
  background-color: inherit;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  z-index: 1;
 }
 
 .download-button .docs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-height: 40px;
  padding: 0 10px;
  border-radius: 4px;
  z-index: 1;
  background-color: #2e6a80;
  border: solid 1px #ffffff2d;
  transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
 }
 
 .download-button:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
 }
 
 .download {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
  z-index: -1;
  border-radius: 4px;
  transform: translateY(0%);
  background-color: #d85c27;
  border: solid 1px #ffffff2d;
  transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
 }
 
 .download-button:hover .download {
  transform: translateY(100%)
 }
 
 .download svg polyline,.download svg line {
  animation: docs 1s infinite;
 }
 
 @keyframes docs {
  0% {
   transform: translateY(0%);
  }
 
  50% {
   transform: translateY(-15%);
  }
 
  100% {
   transform: translateY(0%);
  }
 }
